import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const PortfolioCard = ({ post }) => {

    return(

        <div key={post.fields.slug} className="portfolioItem__holder">
            <Link to={`/portfolio${post.fields.slug}`} className="portfolioItem">
            <div className="portfolioItem__figure">
                <Img alt={post.frontmatter.title} fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
            </div>
            <div className="portfolioItem__body">
                <h5>{post.frontmatter.subtitle}</h5>
                <h4>{post.frontmatter.title}</h4>
                <p className="d-none d-md-inline-block">{post.frontmatter.description}</p>
            </div>
            </Link>
        </div>

    )

}

export default PortfolioCard